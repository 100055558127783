<template>
  <div
    class="ml-n1 mb-1"
    style="border-radius:.35rem;overflow:hidden;"
  >
    <notification-item
      v-for="(item, idx) in notificationGroup.messages"
      :key="`notification_${idx}`"
      :notification="item"
      :class="idx + 1 !== notificationGroup.messages.length ? 'border-bottom' : ''"
      @update="update"
    />
  </div>
</template>

<script>
import { BAvatar, BButton } from 'bootstrap-vue';
import NotificationItem from '@/views/apps/notifications/NotificationItem.vue';
import axios from '@/libs/axios.js';

export default {
  name: 'Notification',
  components: {
    BButton,
    BAvatar,
    NotificationItem,
  },
  props: {
    notificationGroup: {
      type: Object,
    },
  },
  methods: {
    async markAsRead() {
      await axios.post('/notifications/read', {
        ids: [this.notification.id],
      });

      this.$emit('update', { ...this.notification, read_at: new Date() });
    },
    async markAsNew() {
      await axios.post('/notifications/new', {
        ids: [this.notification.id],
      });

      this.$emit('update', { ...this.notification, read_at: null });
    },
    update(val) {
      this.$emit('update', val);
    },
  },
};
</script>

<style lang="scss">
.notifications {
  &__item {
    &--read {
      .timeline-item-point {
        display: none!important;
      }
    }
  }
}
</style>
