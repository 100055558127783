<template>
  <div
    :class="`${bgType(status)} p-1 cursor-pointer`"
    @click="goTo"
  >
    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-2 mb-sm-2">
      <div class="d-flex align-items-center">
        <span
          class="bullet bullet-sm mr-1"
          :class="`bullet-${status}`"
        />
        <h6 class="mb-0 font-weight-bolder">
          {{ notification.title }}
        </h6>
      </div>
      <small class="text-muted">
        {{ notification.created_at | formatDate }}
      </small>
    </div>
    <div class="d-flex justify-content-between align-items-center c-notification__nav">
      <p class="mb-0 notification-description">
        {{ notification.description }}
      </p>
      <b-button
        v-if="!notification.read_at"
        variant="outline-success"
        class="ml-1"
        @click="markAsRead"
      >
        Mark as read
      </b-button>
      <b-button
        v-else
        variant="outline-warning"
        class="ml-1"
        @click="markAsNew"
      >
        Mark as new
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import axios from '@/libs/axios';
import { goToNotificationRoute } from '@/utils/notificationsUtils.js';
import { getStatusByType } from '@/constants/notifications.js';

export default {
  components: {
    BButton,
  },
  props: {
    notification: {
      type: Object,
    },
  },
  computed: {
    status() {
      console.log(this.notification);
      return this.notification.read_at ? 'success' : getStatusByType(this.notification.text_key);
    },
    statuses() {
      return {
        error: 'bg-light-danger',
        warning: 'bg-light-warning',
        info: 'bg-light-info',
        primary: 'bg-light-primary',
        success: 'bg-light-success',
      };
    },

  },
  methods: {
    bgType(type) {
      return this.statuses[type];
    },
    async markAsRead(e) {
      e && e.stopPropagation();

      await axios.post('/notifications/read', {
        ids: [this.notification.id],
      });

      this.$emit('update', { ...this.notification, read_at: new Date() });
    },
    goTo() {
      const { entity, entity_id } = this.notification;
      const route = goToNotificationRoute(entity, entity_id);

      this.markAsRead();

      if (route) {
        this.$router.push(route);
      }
    },
    async markAsNew(e) {
      e.stopPropagation();

      await axios.post('/notifications/new', {
        ids: [this.notification.id],
      });

      this.$emit('update', { ...this.notification, read_at: null });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";

.notification-description {
  color: $headings-color;
}
.dark-layout {
  .notification-description {
    color: $theme-dark-headings-color;
  }
}
</style>
